<template>
    <div
        :id="modalName"
        class="modal"
        role="dialog"
        aria-hidden="true"
        data-backdrop="static"
        data-keyboard="false"
    >
    <div class="modal-dialog modal-xl modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">Application Configuration</h5>
            </div>
            <form @submit.prevent="submitData()">
            <div class="modal-body">
                <input
                    type="hidden"
                    class="form-control"
                    v-model.number="appConfig.id"
                />
                <div class="form-group row">
                    <label class="col-lg-4 col-form-label">Keys:</label>
                    <div class="col-lg-6">
                        <keys-select
                            v-model="appConfig.key"
                            :validation="'required'"
                        ></keys-select>
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-lg-4 col-form-label">Value:</label>
                    <div class="col-lg-6">
                        <textarea
                            type="text"
                            class="form-control"
                            v-model="appConfig.value"
                            name="value"
                            rows="10"/>
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-lg-4 col-form-label">Description:</label>
                    <div class="col-lg-6">
                        <input
                            type="text"
                            class="form-control"
                            v-model="appConfig.description"
                            name="description"
                        />
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-clean btn-bold btn-upper btn-font-md" @click="clearData()" >Close</button>
                <button type="submit" class="btn btn-default btn-bold btn-upper btn-font-md">Save</button>
            </div>
            </form>
        </div>
    </div>
  </div>
</template>

<script>
import SweetAlertTemplate from "./../../_general/SweetAlert";
import BlockUI from "../../_js/BlockUI";
const blockUI = new BlockUI();
const SweetAlert = Vue.extend(SweetAlertTemplate);
import AppConfig from "../../../model/application-configuration";
import { RepositoryFactory } from "../../../repositories/RepositoryFactory";
import KeysSelect from "../../_select/AppConfigKey";
const ApplicationConfigurationRepository = RepositoryFactory.get("appConfig");
export default {
  components: {
      KeysSelect
  },
  data() {
    return {
        appConfig : new AppConfig()
    };
  },
  props: {
    modalName: { type: String },
    modalData: { type: Object }
  },
  provide() {
    return {
      $validator: this.$validator
    };
  },
  methods: {
    clearData: function () {
        $("#" + this.modalName).modal("hide");
        this.$emit("update:showModal", false);
    },
    updateData: function (data) {
        this.appConfig = Object.assign({},data);
    },
    submitData: function () {
      var vx = this;

      this.$validator.validateAll().then(result => {
        if (result) {
          if (vx.appConfig.id) {
            let instance = new SweetAlert().showConfirm({
              swalTitle: "Apakah Anda yakin?",
              swalText: "Perubahan data App Configuration akan tersimpan.",
              swalType: "info",
              onConfirmButton: function () {
                blockUI.blockModal();
                vx.update(vx.appConfig);
              },
              onCancelButton: function () {
                blockUI.unblockModal();
              }
            });
          } else {
            let instance = new SweetAlert().showConfirm({
              swalTitle: "Apakah Anda yakin?",
              swalText: "Data App Configuration akan ditambahkan.",
              swalType: "info",
              onConfirmButton: function () {
                blockUI.blockModal();
                vx.create(vx.appConfig);
              },
              onCancelButton: function () {
                blockUI.unblockModal();
              }
            });
          }
          return;
        }
      });
    },
    async update(payload) {
      var vx = this;

      await ApplicationConfigurationRepository.updateAppConfig(payload)
        .then(response => {
          new Promise((resolve, reject) => {
            vx.$emit("event", resolve);
          }).then((response) => {
            let instance = new SweetAlert().showInfo({
              swalTitle: "Berhasil!",
              swalText: "Perubahan data App Configuration berhasil disimpan.",
              swalType: "success",
              onConfirmButton: function () {
                blockUI.unblockModal();
                vx.clearData();
              }
            });
          });
        })
        .catch(error => {
          let instance = new SweetAlert().showInfo({
            swalTitle: "Gagal!",
            swalText: "Perubahan data App Configuration gagal disimpan.",
            swalType: "error",
            onConfirmButton: function () {
              blockUI.unblockModal();
            }
          });
        });
    },
    async create(payload) {
      var vx = this;
      await ApplicationConfigurationRepository.createAppConfig(payload)
        .then(response => {
          new Promise((resolve, reject) => {
            vx.$emit("event", resolve);
          }).then((response) => {
            let instance = new SweetAlert().showInfo({
              swalTitle: "Berhasil!",
              swalText: "Data App Configuration berhasil ditambahkan.",
              swalType: "success",
              onConfirmButton: function () {
                blockUI.unblockModal();
                vx.clearData();
              }
            });
          });
        })
        .catch(error => {
          let instance = new SweetAlert().showInfo({
            swalTitle: "Gagal!",
            swalText: "Data App Configuration gagal ditambahkan.",
            swalType: "error",
            onConfirmButton: function () {
              blockUI.unblockModal();
            }
          });
        });
    }
  },
  mounted() {
    $("#" + this.modalName).modal();
    if (this.modalData) {
        this.updateData(this.modalData);
    }
  }
}
</script>

<style>

</style>
